
#fromloading:after {
    content: "";
    display: block;
    margin: 0 auto 10px;
    text-align: center;
    width: 50px;
    height: 50px;
    border-left: 8px solid rgba(0,0,0,.2);
    border-right: 8px solid rgba(0,0,0,.2);
    border-bottom: 8px solid rgba(0,0,0,.2);
    border-top: 8px solid #2180c0;
    border-radius: 50px;
    -webkit-animation: rotation .7s infinite linear;
    -moz-animation: rotation .7s infinite linear;
    -o-animation: rotation .7s infinite linear;
    animation: rotation .7s infinite linear;
}
#fromloading{
    position: fixed;
    top: 20%;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    text-align: center;
    background: transparent;
    padding-top: 16%;
    z-index: 9999;
    display: block;
}

#fromloading:before {
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    -moz-opacity: .3;
    -khtml-opacity: .3;
    -webkit-opacity: .3;
    opacity: .3;
    -ms-filter: alpha(opacity=90);
    filter: alpha(opacity=90);
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
@-moz-keyframes rotation {
    from {
        -moz-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -moz-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
@-o-keyframes rotation {
    from {
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    to {
        -o-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}
